import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import * as Wellknown from 'wellknown';
import { TMSLayer as TMSLayerDTO } from '../../../../api/api-tms';
import {
    Button,
    Card,
    Col,
    Container,
    ErrorMessage,
    FormGroup,
    Input,
    Label,
    Row,
    Spinner,
    Title,
} from '../../../style';

import ApiManagedProfiles, {
    ListingMetadata,
    ManagedProfileListingFullDTO,
    UpdateAttachedListing,
} from '../../../../api/api-managed-profiles';
import { ListingFileDTO, ListingFileType, ListingType } from '../../../../api/model';
import { OssUploader } from '../../../../api/oss-uploader';
import NotificationUtil from '../../../../lib/notification-util';
import InputWithClearWrapper from '../../../Shared/input-with-clear-wrapper';
import MapServicesLayerDeleteLegend from '../../MapServicesSharedUI/Layer/map-services-layer-delete-legend';
import MapServicesLayerEditLegend from '../../MapServicesSharedUI/Layer/map-services-layer-edit-legend';
import ListingMetadataEdit from './listing-metadata-edit';
import ListingPreviewImage from './listing-preview-image';
import ProfileListingMap from './profile-listing-map';
import ProfileListingNavigation from './profile-listing-navigation';

interface MatchParams {
    id: string;
    listingId: string;
}

interface ProfileListingProps extends RouteComponentProps<MatchParams> {
    tmsLayer: TMSLayerDTO;
}

const MAX_MAP_ZOOM_LEVEL = 28;

const ProfileListingPage = (props: ProfileListingProps) => {
    const userId = props.match.params.id;
    const listingId = Number(props.match.params.listingId);
    const [updated, setUpdated] = useState(0);
    const [managedListing, setManagedListing] = useState<ManagedProfileListingFullDTO>();
    const [error, setError] = useState<Error | undefined>();
    const [isUpdating, setIsUpdating] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isReloadingMap, setIsReloadingMap] = useState(false);

    const [metadata, setMetadata] = useState<ListingMetadata>();
    const [metadataErrors, setMetadataErrors] = useState<string[]>([]);

    const [isTMS, setIsTMS] = useState<boolean>(true);
    const [legendFile, setLegendFile] = useState<ListingFileDTO>(undefined);
    const [isLoadingLegend, setIsLoadingLegend] = useState<boolean>(false);
    const [adminNotes, setAdminNotes] = useState<string | undefined>(undefined);
    const [base64Preview, setBase64Preview] = useState<string | undefined>(undefined);
    const [boundingBox, setBoundingBox] = useState<string>('');
    const [maxZoomLevel, setMaxZoomLevel] = useState<number | undefined>(undefined);
    const [minZoomLevel, setMinZoomLevel] = useState<number | undefined>(undefined);
    const [mapZoomLevel, setMapZoomLevel] = useState<number>(MAX_MAP_ZOOM_LEVEL);
    const [srs, setSrs] = useState<string>('');

    useEffect(() => {
        setManagedListing(undefined);
        setIsLoading(true);

        const getListing = async () => {
            try {
                const managedListing = await ApiManagedProfiles.getListing(userId, listingId);
                if (!managedListing) {
                    NotificationUtil.showErrorMessage('Listing not found');
                    setError(new Error('Listing not found'));
                    setIsLoading(false);
                    return;
                }
                setManagedListing(managedListing);
            } catch (err) {
                NotificationUtil.showErrorNotificationApi(err);
                setError(err);
            } finally {
                setIsLoading(false);
            }
        };
        getListing();
    }, [userId, listingId]);

    useEffect(() => {
        if (!managedListing) return;
        const { listing, wmsLayer } = managedListing;

        setLegendFile(listing.files.find((v) => v.type == ListingFileType.LEGEND));
        setBoundingBox(listing.geometryWKT);
        setIsTMS(listing.tms);
        setAdminNotes(listing.layerAdminNotes);
        setMaxZoomLevel(listing.maxZoom);
        setSrs(wmsLayer?.srs ?? '');
        setBase64Preview(undefined);
        setIsReloadingMap(true);
    }, [managedListing]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleSave = async () => {
        const errors = [...metadataErrors];
        const geometryWkt = boundingBox ? Wellknown.parse(boundingBox) : undefined;
        if (!geometryWkt) errors.push('Invalid format for bounding box');

        if (errors.length > 0) {
            NotificationUtil.showErrorMessages(errors);
            return;
        }
        setIsUpdating(true);
        const body: UpdateAttachedListing = {
            metadata: metadata,
            adminNotes: adminNotes,
            geometryWKT: boundingBox,
            maxZoom: maxZoomLevel,
            minZoom: minZoomLevel,
            previewBase64: base64Preview,
            tms: isTMS,
            srs: srs,
        };
        try {
            const res = await ApiManagedProfiles.updateListing(userId, listingId, body);
            setManagedListing(res);
        } catch (err) {
            NotificationUtil.showErrorNotificationApi(err);
        } finally {
            setIsUpdating(false);
        }
    };

    const handleGeneratePreview = async (base64: string) => {
        setBase64Preview(base64);
    };

    const handleValidateFileType = (file: File) => {
        const validTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        return validTypes.includes(file.type);
    };

    const handleUploadLegend = async (file: File) => {
        if (!handleValidateFileType(file)) {
            alert('Please use a supported image type (jpg or png)');
            return;
        }

        setIsLoadingLegend(true);
        try {
            const credentials = await ApiManagedProfiles.createListingFileAndGetUploadCredentials(userId, listingId);
            const uploader = new OssUploader(credentials);
            await uploader.multipartUpload(file, file.name, (_) => {}); // eslint-disable-line @typescript-eslint/no-empty-function
            await setTimeout(() => {
                setUpdated(updated + 1);
                setIsLoadingLegend(false);
            }, 3000);
        } catch (err) {
            setError(err);
            setIsLoadingLegend(false);
        }
    };

    const handleDeleteLegend = async () => {
        setIsLoadingLegend(true);
        try {
            ApiManagedProfiles.deleteListingFile(userId, listingId, legendFile.id);
            setLegendFile(undefined);
        } catch (err) {
            setError(err);
        } finally {
            setIsLoadingLegend(false);
        }
    };

    const handleSwitchTMS = () => {
        setIsTMS(!isTMS);
    };

    useEffect(() => {
        if (isReloadingMap) {
            requestAnimationFrame(() => {
                setIsReloadingMap(false);
            });
        }
    }, [isReloadingMap]);

    useEffect(() => {
        setIsReloadingMap(true);
    }, [srs, isTMS]);

    const memoizedOnMetadataChange = useCallback(
        (m, e) => {
            setMetadata(m);
            setMetadataErrors(e);
        },
        [setMetadata, setMetadataErrors]
    );

    if (error) {
        return (
            <Container>
                <Title>Manage Listing</Title>
                <Row>
                    <Col md={12}>
                        <Card>
                            <ErrorMessage>{error.message}</ErrorMessage>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }

    if (isLoading) {
        return (
            <Container>
                <Spinner />
            </Container>
        );
    }

    if (error || !managedListing) {
        return (
            <Container>
                <Title>Manage Listing</Title>
                <Row>
                    <Col md={12}>
                        <Card>
                            <ErrorMessage>{error?.message || 'Something has gone wrong!'}</ErrorMessage>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }

    const { listing, wmsLayer } = managedListing;

    return (
        <Container>
            <Title>{`${listing?.id}: ${listing?.title} [${listing.listingType}]`}</Title>
            <Row>
                <Col md={12}>
                    <Card>
                        <ProfileListingNavigation
                            userId={userId}
                            listing={listing}
                            onListingUpdated={(updated) => setManagedListing(updated)}
                        />
                    </Card>
                </Col>
            </Row>

            {!listing?.processing && (
                <Row>
                    <Col md={5}>
                        <Card>
                            <ListingMetadataEdit listing={listing} onChange={memoizedOnMetadataChange} />
                            <FormGroup>
                                <Label for="preview">
                                    {`Preview image ${
                                        base64Preview || listing.previewUrl ? ': Click preview to change' : ''
                                    }`}
                                </Label>
                                <ListingPreviewImage
                                    previewUrl={listing.previewUrl}
                                    base64Preview={base64Preview}
                                    onPreviewUpdated={(base64) => setBase64Preview(base64)}
                                />
                            </FormGroup>

                            <FormGroup>
                                <LegendLabel for="legend">
                                    Legend
                                    <span>
                                        <LegendControls>
                                            {legendFile ? (
                                                <LegendItem>
                                                    <MapServicesLayerDeleteLegend
                                                        handleConfirmDeleteLegend={() => handleDeleteLegend()}
                                                    />
                                                    <LineDivider>|</LineDivider>
                                                </LegendItem>
                                            ) : null}
                                            <LegendItem>
                                                <MapServicesLayerEditLegend
                                                    onSelectUploadLegend={(file) => handleUploadLegend(file)}
                                                    legendUrl={legendFile?.url}
                                                />
                                            </LegendItem>
                                        </LegendControls>
                                    </span>
                                </LegendLabel>
                                {legendFile && !isLoadingLegend ? <Legend src={legendFile?.url} /> : null}
                                {isLoadingLegend ? <Spinner /> : null}
                            </FormGroup>

                            <FormGroup>
                                <Label for="adminNotes">Admin Notes</Label>
                                <Input
                                    type="textarea"
                                    rows="3"
                                    name="adminNotes"
                                    value={adminNotes}
                                    onChange={(e) => setAdminNotes(e.target.value)}
                                />
                            </FormGroup>

                            {wmsLayer?.srsOptions && (
                                <FormGroup>
                                    <Label for="boundingBox">SRS Options</Label>
                                    <Input
                                        value={srs}
                                        onChange={(e) => {
                                            setSrs(e.target.value);
                                        }}
                                        type="select"
                                        name="srs"
                                    >
                                        {wmsLayer.srsOptions.map((value) => (
                                            <option key={value} value={value}>
                                                {value}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            )}
                            {listing.listingType == ListingType.EXTERNAL_TILE_LAYER && (
                                <FormGroup switch>
                                    <TMSSwitch
                                        id="tms-checkbox"
                                        type="switch"
                                        checked={isTMS}
                                        onChange={() => {
                                            handleSwitchTMS();
                                        }}
                                    />
                                    <Label for="tms-checkbox">{`TMS Layer: ${isTMS ? 'Yes' : 'No'}`}</Label>
                                </FormGroup>
                            )}
                        </Card>
                    </Col>

                    <Col md={7}>
                        <Card>
                            {isReloadingMap ? (
                                <Spinner />
                            ) : (
                                <ProfileListingMap
                                    listing={listing}
                                    onGeneratePreview={(base64: string) => {
                                        handleGeneratePreview(base64);
                                    }}
                                    onUsePreviewBoundingBox={(boundingBox: string) => {
                                        setBoundingBox(boundingBox);
                                    }}
                                    setMapZoomLevel={setMapZoomLevel}
                                    srs={srs}
                                    tms={isTMS ?? listing.tms}
                                />
                            )}
                        </Card>
                        <Card>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="maxZoomLevel">Min Zoom Level (optional)</Label>
                                        <InputWithButton>
                                            <InputWithClearWrapper
                                                value={minZoomLevel}
                                                onClear={() => setMinZoomLevel(undefined)}
                                            >
                                                <Input
                                                    min={1}
                                                    max={28}
                                                    step={1}
                                                    type="number"
                                                    placeholder="Min zoom level"
                                                    id="minZoomLevel"
                                                    name="minZoomLevel"
                                                    // title={`The zoom level the TMS displays to after the tiles ended.`}
                                                    value={Math.round(minZoomLevel)}
                                                    onChange={(e) =>
                                                        setMinZoomLevel(Math.round(Number(e.target.value)))
                                                    }
                                                />
                                            </InputWithClearWrapper>
                                            <UpdateZoomButton
                                                title="Use the zoom level from the map"
                                                onClick={() => setMinZoomLevel(Math.round(mapZoomLevel))}
                                            >
                                                {`Set min zoom to ${Math.round(mapZoomLevel)}`}
                                            </UpdateZoomButton>
                                        </InputWithButton>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="maxZoomLevel">Max Zoom Level (optional)</Label>
                                        <InputWithButton>
                                            <InputWithClearWrapper
                                                value={minZoomLevel}
                                                onClear={() => setMinZoomLevel(undefined)}
                                            >
                                                <Input
                                                    min={1}
                                                    max={28}
                                                    step={1}
                                                    type="number"
                                                    placeholder="Max zoom level"
                                                    id="tms-maxZoomLevel"
                                                    name="maxZoomLevel"
                                                    title={`The zoom level the TMS displays to after the tiles ended.`}
                                                    value={Math.round(maxZoomLevel)}
                                                    onChange={(e) =>
                                                        setMaxZoomLevel(Math.round(Number(e.target.value)))
                                                    }
                                                />
                                            </InputWithClearWrapper>

                                            <UpdateZoomButton
                                                title="Use the zoom level from the map"
                                                onClick={() => setMaxZoomLevel(Math.round(mapZoomLevel))}
                                            >
                                                {`Set max zoom to ${Math.round(mapZoomLevel)}`}
                                            </UpdateZoomButton>
                                        </InputWithButton>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup>
                                <Label for="boundingBox">Bounding Box WKT</Label>
                                <InputWithButton>
                                    <Input
                                        type="text"
                                        name="boundingBox"
                                        value={boundingBox}
                                        onChange={(e) => setBoundingBox(e.target.value)}
                                    />
                                    <UpdateBoundsButton onClick={() => setIsReloadingMap(true)}>
                                        View Bounds
                                    </UpdateBoundsButton>
                                </InputWithButton>
                            </FormGroup>

                            {/* <TMSLayerDeleteAction serverId={userId} layerId={listingId}>
                            <Button color="danger">Delete Layer</Button>
                            <Label>
                                Warning: This action cannot be undone and the layer will need to be re-entered!
                            </Label>
                        </TMSLayerDeleteAction> */}
                        </Card>
                    </Col>
                    <Col md={12}>
                        <Card>
                            <SaveButton disabled={isUpdating} onClick={() => handleSave()}>
                                {isUpdating ? <Spinner /> : null}
                                Save changes
                            </SaveButton>
                        </Card>
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default ProfileListingPage;

const LegendLabel = styled(Label)`
    width: 100%;
    margin-bottom: 10px;

    span {
        float: right;
    }
`;

const Legend = styled.img`
    display: block;
    max-width: 100%;
`;

const LegendItem = styled.div`
    display: flex;
    flex-direction: row;
`;

const LineDivider = styled.div`
    margin: 0px 8px;
    margin-top: -3px;
    font-size: 20px;
    height: 20px;
`;

const LegendControls = styled.div`
    display: flex;
`;

const TMSSwitch = styled(Input)`
    margin: 0px 10px 15px 0px;
    height: 20px;
    width: 40px !important;
`;

const UpdateBoundsButton = styled(Button)`
    margin-left: 10px;
    margin-right: 0px;
    width: 200px;
`;

const UpdateZoomButton = styled(Button)`
    margin-left: 10px;
    margin-right: 0px;
    width: 100%;
`;

const InputWithButton = styled.div`
    display: flex;
    flex-direction: row;
`;

const SaveButton = styled(Button)`
    width: 200px;
    align-self: center;
`;
