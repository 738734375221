import React, { useCallback, useEffect, useState } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { toast } from 'react-toastify';
import ApiAnalytics from '../../../api/api-analytics';
import { AnalyticsMapViewDTO, MapViewsDTO } from '../../../api/model';
import { DatesFromTo, ProductName } from '../../../api/model-api';
import Config from '../../../config';
import InputDateRange, { DEFAULT_DATE_RANGES, LAST_7_DAYS } from '../../Shared/Filters/DateRange/input-date-range';
import { Card, Col, FormGroup, Input, Label, Row, Spinner, Statistic, Subtitle } from '../../style';
import ReportTable from '../report-table';
import ViewHelper from '../../view-helper';
import { endOfDay, startOfDay } from 'date-fns';

const buildMapLink = (dto: MapViewsDTO): string => {
    if (dto.product === ViewHelper.ANALYTICS_PRODUCT_NAMES['MAP_DRAW']) {
        return `${Config.CLIENT_URL}/draw/${dto.id}`;
    }
    if (dto.product === ViewHelper.ANALYTICS_PRODUCT_NAMES['EMBED_MAP']) {
        return `${Config.EMBED_MAP_URL}/maps/${dto.id}`;
    }

    return `${Config.CLIENT_URL}/browse/${dto.id}`;
};

const PRODUCTS: ProductName[] = [
    'IMAGE',
    'MAP',
    'ORDER',
    'EMBED_MAP',
    'MAP_DRAW',
    'MAP_CANVA',
    'WMS',
    'WMTS',
    'COG',
    'EXTERNAL_TILE_LAYER',
];
const TABLE_COLUMNS_LISTINGS: ColumnDescription[] = [
    {
        dataField: 'id',
        text: 'ID',
        sort: true,
    },
    {
        dataField: 'product',
        text: 'Product',
        sort: true,
    },
    {
        dataField: 'total',
        text: 'Total',
        sort: true,
    },
    {
        dataField: 'id',
        text: 'Link',
        formatter: (_cell, dto) => {
            const link = buildMapLink(dto);
            return (
                <a href={link} target="_blank" rel="noopener noreferrer">
                    {link}
                </a>
            );
        },
    },
];

const MapViews = () => {
    const [dates, setDates] = useState<DatesFromTo>(LAST_7_DAYS);
    const [mapViews, setMapViews] = useState<AnalyticsMapViewDTO>(undefined);
    const [views, setViews] = useState<MapViewsDTO[]>(undefined);
    const [filterProduct, setFilterProduct] = useState<string>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const handleFilterMapViewsByDate = useCallback(
        async (dates: DatesFromTo) => {
            setLoading(true);
            try {
                const roundedRange = { from: startOfDay(dates.from), to: endOfDay(dates.to) };
                const res = await ApiAnalytics.apiGetAnalyticsMapViews(roundedRange);

                if (res && filterProduct) {
                    const filteredResults = res.views.filter((images) => images.product === filterProduct);
                    setMapViews(res);
                    setViews(filteredResults);
                    return;
                }
                setMapViews(res);
                setViews(res.views);
            } catch (err) {
                toast.error('Error fetching hashtags', err);
            } finally {
                setLoading(false);
            }
        },
        [filterProduct]
    );

    useEffect(() => {
        handleFilterMapViewsByDate(dates);
    }, [dates, filterProduct, handleFilterMapViewsByDate]);

    return (
        <React.Fragment>
            <Card>
                <FormGroup>
                    <Label for="date-range">Filter by Date</Label>
                    <InputDateRange fromTo={dates} onChange={setDates} selectedDate={DEFAULT_DATE_RANGES.LAST_7_DAYS} />
                </FormGroup>
            </Card>
            <Row>
                <Col>
                    <Card title="Total number of Views">
                        <Subtitle>Total Views</Subtitle>
                        <Statistic>
                            {loading ? <Spinner /> : !mapViews?.total ? '0' : mapViews.total.toLocaleString()}
                        </Statistic>
                    </Card>
                </Col>
                <Col>
                    <Card title="Total number of unique images viewed">
                        <Subtitle>Images</Subtitle>
                        <Statistic>
                            {loading ? (
                                <Spinner />
                            ) : !mapViews?.totalImages ? (
                                '0'
                            ) : (
                                mapViews.totalImages.toLocaleString()
                            )}
                        </Statistic>
                    </Card>
                </Col>
                <Col>
                    <Card title="Total number of unique map views">
                        <Subtitle>Maps</Subtitle>
                        <Statistic>
                            {loading ? <Spinner /> : !mapViews?.totalMaps ? '0' : mapViews.totalMaps.toLocaleString()}
                        </Statistic>
                    </Card>
                </Col>
                <Col>
                    <Card title="Total number of embedded map views">
                        <Subtitle>Embedded Maps</Subtitle>
                        <Statistic>
                            {loading ? <Spinner /> : !mapViews?.totalEmbd ? '0' : mapViews.totalEmbd.toLocaleString()}
                        </Statistic>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Subtitle>Canva</Subtitle>
                        <Statistic>
                            {loading ? (
                                <Spinner />
                            ) : !mapViews?.totalMapCanva ? (
                                '0'
                            ) : (
                                mapViews.totalMapCanva.toLocaleString()
                            )}
                        </Statistic>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Subtitle>Draw Projects</Subtitle>
                        <Statistic>
                            {loading ? (
                                <Spinner />
                            ) : !mapViews?.totalMapDraw ? (
                                '0'
                            ) : (
                                mapViews.totalMapDraw.toLocaleString()
                            )}
                        </Statistic>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Subtitle>WMS Layers</Subtitle>
                        <Statistic>
                            {loading ? <Spinner /> : !mapViews?.totalWms ? '0' : mapViews.totalWms.toLocaleString()}
                        </Statistic>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Subtitle>WMTS Layers</Subtitle>
                        <Statistic>
                            {loading ? <Spinner /> : !mapViews?.totalWmts ? '0' : mapViews.totalWmts.toLocaleString()}
                        </Statistic>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Subtitle>COG</Subtitle>
                        <Statistic>
                            {loading ? <Spinner /> : !mapViews?.totalCog ? '0' : mapViews.totalCog.toLocaleString()}
                        </Statistic>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Subtitle>TMS Layers</Subtitle>
                        <Statistic>
                            {loading ? (
                                <Spinner />
                            ) : !mapViews?.totalExtTileLayer ? (
                                '0'
                            ) : (
                                mapViews.totalExtTileLayer.toLocaleString()
                            )}
                        </Statistic>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Subtitle>Orders</Subtitle>
                        <Statistic>
                            {loading ? (
                                <Spinner />
                            ) : !mapViews?.totalOrders ? (
                                '0'
                            ) : (
                                mapViews.totalOrders.toLocaleString()
                            )}
                        </Statistic>
                    </Card>
                </Col>
            </Row>
            {!views || loading ? (
                <Card height="600px">
                    <Spinner />
                </Card>
            ) : (
                <React.Fragment>
                    <Card>
                        <Col>
                            <FormGroup>
                                <Label for="hashtag-filter">Search by Product</Label>
                                <Input
                                    data-testid="select-reason"
                                    type="select"
                                    value={filterProduct}
                                    onChange={(value) => setFilterProduct(value.target.value)}
                                >
                                    <option key="" value="">
                                        All
                                    </option>
                                    {PRODUCTS.map((s) => (
                                        <option key={s} value={ViewHelper.ANALYTICS_PRODUCT_NAMES[s]}>
                                            {ViewHelper.ANALYTICS_PRODUCT_NAMES[s]}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Card>
                    <Card>
                        <ReportTable
                            items={views}
                            columns={TABLE_COLUMNS_LISTINGS}
                            keyField="label"
                            defaultSorted={[{ dataField: 'total', order: 'desc' }]}
                            csvExport
                        />
                    </Card>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default MapViews;
