import React, { useCallback, useEffect, useState } from 'react';
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from '../style';
import styled from 'styled-components';
import { featuredCategories } from '../../api/api-wms';
import { useDispatch, useSelector } from 'react-redux';
import { actionClearFeatured, actionRefreshFeatured } from '../../store/AppStore/actions';
import { selectFeatured } from '../../store/AppStore/selectors';
import config from '../../config';
import FeaturedSelection from './featured-selection';

const FeaturedTabs = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [open, setOpen] = useState('0');

    const dispatch = useDispatch();
    const featuredMaps = useSelector(selectFeatured);

    const toggle = (id: string, featureType: string) => {
        if (isLoading) {
            return;
        }

        if (open !== id) {
            handleClearFeatured();
            handleGetFeatured(featureType);
            setOpen(id);
        }
    };

    const handleGetFeatured = useCallback(
        (featureType: string) => {
            setIsLoading(true);
            dispatch(actionRefreshFeatured(featureType));
        },
        [dispatch]
    );

    const handleClearFeatured = useCallback(() => {
        if (featuredMaps?.length) {
            setIsLoading(false);
            dispatch(actionClearFeatured());
        }
    }, [dispatch, featuredMaps?.length]);

    useEffect(() => {
        if (!featuredMaps?.length && open === '0') {
            handleGetFeatured('best');
        }

        if (featuredMaps) {
            setIsLoading(false);
        }
        // Disabled `open` for this line, it is a little funky using tabs for requesting data
        // In the case of this it just stops 2 requests firing when the user changes to the hottest tab but allows it to initially check when route opened
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [featuredMaps, handleGetFeatured]);

    return (
        <React.Fragment>
            <Card>
                <FeaturedCategoryNav tabs>
                    {featuredCategories.map((category, index) => {
                        return (
                            <NavItem key={`featured-tabs-${index}`}>
                                <NavLink
                                    className={open && index.toString() === open ? 'active' : ''}
                                    onClick={() => toggle(index.toString(), category.value)}
                                >
                                    {category.label.toUpperCase()}
                                </NavLink>
                            </NavItem>
                        );
                    })}
                </FeaturedCategoryNav>
            </Card>
            <TabContent activeTab={open}>
                {featuredCategories.map((category, index) => {
                    return (
                        <TabPane key={index} tabId={index.toString()}>
                            <FeaturedSelection
                                featuredMaps={featuredMaps}
                                type={category.value}
                                name={category.label}
                                count={category.count || config.FEATURED_CATEGORY_MAP_COUNT}
                                isLoading={isLoading}
                            />
                        </TabPane>
                    );
                })}
            </TabContent>
        </React.Fragment>
    );
};

export default FeaturedTabs;

const FeaturedCategoryNav = styled(Nav)`
    border-bottom: 1px solid rgba(116, 116, 116, 0.3) !important;
    cursor: pointer;

    .active {
        background-color: #eed926 !important;
        color: rgba(0, 0, 0, 0.87) !important;
        font-weight: 600;
        text-decoration: underline;
    }
`;
