import React, { useEffect, useState } from 'react';
import { PrimaryCategory, categories } from '../../../../api/api-tms';
import TagInputField from '../../../Shared/tag-input-field';
import { FormGroup, Input, Label, Multiselect } from '../../../style';

import { ListingMetadata } from '../../../../api/api-managed-profiles';
import { TemporalCategory, temporalCategoryOptions } from '../../../../api/api-wms';
import { ListingDTO } from '../../../../api/model';
import { MarkdownTextArea } from '../../../Shared/markdown-textarea';
import ViewHelper from '../../../view-helper';

interface ListingMetadataEditProps {
    listing: ListingDTO;
    onChange: (metatada: ListingMetadata, errors: string[]) => void;
}

const MAX_TITLE_LENGTH = 100;
const MIN_TITLE_LENGTH = 3;
const MAX_DESCRIPTION_LENGTH = 200;

const ListingMetadataEdit = ({ listing, onChange }: ListingMetadataEditProps) => {
    const [title, setTitle] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [primaryCategories, setPrimaryCategories] = useState<PrimaryCategory[]>([]);
    const [temporalCategory, setTemporalCategory] = useState<TemporalCategory | undefined>(undefined);
    const [tags, setTags] = useState<string[]>([]);
    const [license, setLicense] = useState<string | undefined>();
    const [showTemporalCategory, setShowTemporalCategory] = useState<boolean>(false);

    useEffect(() => {
        setTitle(listing.title);
        setDescription(listing.description);
        setTags(listing.tags || []);
        setLicense(listing.license);
        const currentCategories: PrimaryCategory[] = listing.categories?.map((category) => {
            return categories.find((c) => c.value === category?.toLowerCase());
        });
        setPrimaryCategories(currentCategories || []);

        const temporalCategory = listing.categories
            .map((category) => category.toLowerCase())
            .map((categoryLowerCase) => temporalCategoryOptions.find((c) => c.value === categoryLowerCase))
            .find((foundTemporalCategory) => !!foundTemporalCategory);

        if (temporalCategory) {
            setTemporalCategory(temporalCategory);
        }
        setShowTemporalCategory(
            temporalCategory != undefined || listing.listingType === 'WMS' || listing.listingType === 'WMTS'
        );
        console.log('listing-metadata-edit: ', currentCategories, listing.tags, listing.categories);
    }, [listing]);

    useEffect(() => {
        const errors = [];
        if (title?.length < MIN_TITLE_LENGTH)
            errors.push(`Please review the title, minimum characters ${MIN_TITLE_LENGTH}`);

        if (description?.length < MIN_TITLE_LENGTH)
            errors.push(`Please review the title, minimum characters ${MIN_TITLE_LENGTH}`);

        if (title?.length > MAX_TITLE_LENGTH)
            errors.push(`Please review the title, maximum characters ${MAX_TITLE_LENGTH}`);

        if (description?.split(' ')?.length > MAX_DESCRIPTION_LENGTH)
            errors.push(`Please review the description, maximum word count ${MAX_DESCRIPTION_LENGTH}`);

        if (!primaryCategories?.length || primaryCategories?.length > 3)
            errors.push('Please select between 1 - 3 categories');

        const selectedCategories = primaryCategories?.map((t) => t?.value) || [];
        if (temporalCategory?.value) {
            selectedCategories.push(temporalCategory.value);
        }
        const metadata: ListingMetadata = {
            title: title,
            description: description,
            categories: selectedCategories,
            tags: tags,
            license: license,
        };
        console.log('metadata: ', metadata, errors);
        onChange(metadata, errors);
    }, [title, description, primaryCategories, tags, license, temporalCategory, onChange]);

    return (
        <React.Fragment>
            <FormGroup>
                <Label for="title">Title</Label>
                <Input type="text" name="title" value={title} onChange={(e) => setTitle(e.target.value)} />
            </FormGroup>

            <FormGroup>
                <Label for="description">Description</Label>
                <MarkdownTextArea
                    value={description}
                    setInputValue={(value) => {
                        setDescription(value);
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label for="category">Categories</Label>
                <Multiselect
                    isMulti
                    closeMenuOnSelect={false}
                    name="category"
                    options={categories}
                    value={primaryCategories}
                    onChange={(selectedCategories) => {
                        setPrimaryCategories(selectedCategories);
                    }}
                />
            </FormGroup>

            {showTemporalCategory && (
                <FormGroup>
                    <Label for="temporal-category">Temporal Category (Optional)</Label>
                    <Multiselect
                        name="temporal-category"
                        options={temporalCategoryOptions}
                        defaultValue={temporalCategory}
                        onChange={(selectedTemporal: TemporalCategory) => {
                            setTemporalCategory(selectedTemporal);
                        }}
                    />
                </FormGroup>
            )}

            <FormGroup>
                <Label for="tags">Tags (to help with search)</Label>
                <TagInputField tags={tags} onTagInput={(tags) => setTags(tags)} />
            </FormGroup>

            <FormGroup>
                <Label for="license">License</Label>
                <Input
                    value={license}
                    onChange={(e) => {
                        setLicense(e.target.value);
                    }}
                    type="select"
                    name="license"
                >
                    {!license && <option>Choose license</option>}
                    {Object.values(ViewHelper.LISTINGS_LICENSES).map((value) => (
                        <option key={value} value={value}>
                            {value}
                        </option>
                    ))}
                </Input>
            </FormGroup>
        </React.Fragment>
    );
};

export default ListingMetadataEdit;
