import { MainReportDTO, SoarReportDTO, SubdomainsReportDTO } from '../../api/model';
import { AppState } from '../root-reducer';

export const selectMainReport = (state: AppState): MainReportDTO | undefined => state.reportsStore.mainReport;
export const selectSoarReport = (state: AppState): SoarReportDTO | undefined => state.reportsStore.soarReport;
export const selectSubdomainsReport = (state: AppState): SubdomainsReportDTO | undefined =>
    state.reportsStore.subdomainsReport;

export const selectAnalyticsHeatmapFilter = (state: AppState) => state.reportsStore.analyticsHeatmapFilter;
export const selectAnalyticsHeatmap = (state: AppState) => state.reportsStore.analyticsHeatmap;
export const selectAnalyticsHeatmapError = (state: AppState) => state.reportsStore.analyticsHeatmapError;

export const selectAnalyticsMonthlyReports = (state: AppState) => state.reportsStore.analyticsMonthlyReports;
export const selectAnalyticsMonthlyReportsError = (state: AppState) => state.reportsStore.analyticsMonthlyReportsError;

export const selectCloudflareDailyFilter = (state: AppState) => state.reportsStore.cloudflareDailyFilter;
export const selectCloudflareDailyReport = (state: AppState) => state.reportsStore.cloudflareDailyReport;
export const selectCloudflareDailyReportError = (state: AppState) => state.reportsStore.cloudflareDailyReportError;

export const selectCloudflareMonthlyReport = (state: AppState) => state.reportsStore.cloudflareMonthlyReport;
export const selectCloudflareMonthlyReportError = (state: AppState) => state.reportsStore.cloudflareMonthlyReportError;

export const selectDrawProjectsReport = (state: AppState) => state.reportsStore.drawProjectsReport;
export const selectDrawProjectsReportError = (state: AppState) => state.reportsStore.drawProjectsReportError;
