import React, { useEffect, useState } from 'react';
import { ListingType } from '../../api/model';
import { SearchParams } from '.';
import { startOfDay, endOfDay } from 'date-fns';
import { Button, Card, Col, FormGroup, Input, Label, Row, Spinner } from '../style';
import InputDateRange from '../Shared/Filters/DateRange/input-date-range';
import { DatesFromTo } from '../../api/model-api';
import styled from 'styled-components';
import { categories } from '../../api/api-wms';

interface Props {
    onChange: (params: SearchParams) => void;
    handleTableView: () => void;
    tableView: boolean;
}

type SearchState = 'MULTIPLE_SEARCH' | 'SINGLE_SEARCH'

const Filter = (props: Props) => {
    const [listingId, setListingId] = useState<number | undefined>()
    const [keywords, setKeywords] = useState('');
    const [email, setEmail] = useState('');
    const [category, setCategory] = useState('');
    const [dates, setDates] = useState<DatesFromTo | undefined>(undefined);
    const [status, setStatus] = useState<string>('APPROVED');
    const [type, setType] = useState<string>('');
    const [searchState, setSearchState] = useState<SearchState>('MULTIPLE_SEARCH');

    const handleSubmit = async () => {
        const lt: ListingType = ListingType[type];
        const params: SearchParams = (searchState === 'SINGLE_SEARCH') ?
            { listingId: listingId }
            :
            {
                category: category,
                keywords: keywords,
                status: status,
                listingType: lt,
                from: dates?.from ? startOfDay(dates.from)?.getTime() / 1000 : undefined,
                to: dates?.to ? endOfDay(dates.to)?.getTime() / 1000 : undefined,
                email: email,
            };
        props.onChange(params);
    };

    const toggleSearchState = () => {
        if (searchState === 'SINGLE_SEARCH') {
            setSearchState('MULTIPLE_SEARCH')
        } else if (searchState === 'MULTIPLE_SEARCH') {
            setSearchState('SINGLE_SEARCH')
        }
    }

    useEffect(() => {
        handleSubmit();
        // We trigger the handleSubmit to refetch if the view changes but do not want it firing as a callback
        // alternative is to add another function to handle it
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tableView]);

    return (
        <Card>
            {(searchState === 'MULTIPLE_SEARCH') &&
                (<Row>
                    <Col md={3}>
                        <FormGroup>
                            <Label for="category">Filter by Category</Label>
                            {categories ? (
                                <Input
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                    type="select"
                                    name="category"
                                    id="category"
                                >
                                    <option value="">All</option>
                                    {categories.map((cat, i) => {
                                        return <option key={`${cat}-${i}`} value={cat.value} label={cat.label} />;
                                    })}
                                </Input>
                            ) : (
                                <Spinner />
                            )}
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label for="idType">Filter by Type</Label>
                            <Input
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                type="select"
                                name="type"
                                id="idType"
                            >
                                <option value="">All</option>
                                <option value={ListingType.IMAGE}>Image</option>
                                <option value={ListingType.TILE_LAYER}>Map</option>
                                <option value={ListingType.WMS}>WMS</option>
                                <option value={ListingType.WMTS}>WMTS</option>
                                <option value={ListingType.COG}>COG</option>
                                <option value={ListingType.EXTERNAL_TILE_LAYER}>EXTERNAL TILE LAYER</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label for="idStatus">Filter by Status</Label>
                            <Input
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                type="select"
                                name="status"
                                id="idStatus"
                            >
                                <option value="APPROVED">Approved</option>
                                <option value="PENDING">Pending</option>
                                <option value="REJECTED">Rejected</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <Label for="date-range">Filter by Dates</Label>
                        <InputDateRange fromTo={dates} onChange={setDates} />
                    </Col>
                </Row>
                )
            }

            {(searchState === 'MULTIPLE_SEARCH') &&
                (<Row>
                    <Col md={6} xs={12}>
                        <FormGroup>
                            <Label for="keywords">Search by keywords</Label>
                            <Input
                                value={keywords}
                                onChange={(e) => setKeywords(e.target.value)}
                                type="text"
                                name="keywords"
                                id="keywords"
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6} xs={12}>
                        <FormGroup>
                            <Label for="email">Filter by owner email</Label>
                            <Input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                                name="email"
                                id="email"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                )
            }

            {(searchState === 'SINGLE_SEARCH') &&
                (<Row>
                    <Col md={12} xs={12}>
                        <FormGroup>
                            <Label for="listingId">Search by Listing ID</Label>
                            <Input
                                value={listingId}
                                onChange={(e) => { setListingId(e.target.value) }}
                                type="number"
                                name="listingId"
                                id="listingId"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                )}


            <Row>
                <Col md={6}>
                    <Button onClick={handleSubmit}>Search</Button>
                </Col>
                <Col md={6}>
                    <TableIconContainer>
                        <Button color="link" onClick={toggleSearchState}>
                            {searchState === 'MULTIPLE_SEARCH' ? 'Search specific map by ID' : 'Search maps using various filters'}
                        </Button>
                        <Button color="link" onClick={props.handleTableView}>
                            {props.tableView ? 'View Cards' : 'View List'}
                        </Button>
                    </TableIconContainer>
                </Col>
            </Row>
        </Card>
    );
};

export default Filter;

const TableIconContainer = styled.div`
    float: right;
`;
