import React from 'react';
import { Multiselect } from '../style';
import config from '../../config';

interface PrimaryCategory {
    value: string;
    label: string;
    count?: number;
}

const categories: PrimaryCategory[] = [
    { value: 'agriculture', label: 'Agriculture' },
    { value: 'climate', label: 'Climate' },
    { value: 'earth-art', label: 'Earth Art' },
    { value: 'economic', label: 'Economic' },
    { value: 'environment', label: 'Environment' },
    { value: 'geology', label: 'Geology' },
    { value: 'history', label: 'History' },
    { value: 'imagery', label: 'Imagery' },
    { value: 'marine', label: 'Marine' },
    { value: 'political', label: 'Political' },
    { value: 'radar', label: 'Radar' },
    { value: 'satellite', label: 'Satellite' },
    { value: 'sar', label: 'SAR' },
    { value: 'terrain', label: 'Terrain' },
    { value: 'transport', label: 'Transport ' },
    { value: 'urban', label: 'Urban' },
    // Continent Featured Maps
    { value: 'global-maps', label: 'Global Maps', count: config.FEATURED_CONTINENT_MAP_COUNT },
    { value: 'australia', label: 'Australia', count: config.FEATURED_CONTINENT_MAP_COUNT },
    { value: 'asia', label: 'Asia', count: config.FEATURED_CONTINENT_MAP_COUNT },
    { value: 'europe', label: 'Europe', count: config.FEATURED_CONTINENT_MAP_COUNT },
    { value: 'north-america', label: 'North America', count: config.FEATURED_CONTINENT_MAP_COUNT },
    { value: 'south-america', label: 'South America', count: config.FEATURED_CONTINENT_MAP_COUNT },
    { value: 'africa', label: 'Africa', count: config.FEATURED_CONTINENT_MAP_COUNT },
];

interface Props {
    values?: string[];
    onCategoryInput: (tags: string[]) => void;
    disabled?: boolean;
}

const mapExisting = (values?: string[]): PrimaryCategory[] => {
    return values?.map((category) => {
        return categories.find((c) => c.value === category?.toLowerCase());
    });
};

const mapIntoCategories = (primaryCategories?: PrimaryCategory[]): string[] => {
    return primaryCategories?.map((pc) => pc.value) || [];
};

const CategoriesInput = ({ values, onCategoryInput, disabled }: Props) => {
    return (
        <Multiselect
            isMulti
            disabled={disabled}
            closeMenuOnSelect={false}
            name="categories"
            options={categories ? categories : []}
            value={mapExisting(values)}
            onChange={(selectedCategories) => {
                onCategoryInput(mapIntoCategories(selectedCategories));
            }}
        />
    );
};

export default CategoriesInput;
