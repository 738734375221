import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ContactSubjectType, UserRole } from '../api/model';
import { OrderStatus } from '../api/model-orders';
import { selectContactUs, selectSupermapQueueLength, selectUser, selectOrders } from '../store/AppStore/selectors';
import { selectReportedListings } from '../store/ReportedListingsStore/selectors';
import NavGroup from './navigation-group';

export interface NavigationPermissionItem {
    name: string;
    route: string;
    requiredRole: UserRole;
    badgeCount?: number;
}

const Navigation = () => {
    const user = useSelector(selectUser);
    const contactUs = useSelector(selectContactUs);
    const reportedListings = useSelector(selectReportedListings);
    const pendingReportedMaps = reportedListings?.filter((rl) => rl.review === 'PENDING')?.length || 0;
    const pendingContactUsInquires = contactUs
        ? contactUs.filter((item) => item.subject !== ContactSubjectType.PROBLEM_WITH_LISTING).length
        : 0;
    const pendingUploadReviews = useSelector(selectSupermapQueueLength);
    const tasks = useSelector(selectOrders);

    const pendingSatelliteOrders = useMemo(() => {
        if (!tasks || tasks.length === 0) return 0;
        const needsApproving = tasks.filter((t) => t.status === OrderStatus.REVIEW_SOAR);
        const needsQA = tasks.filter((t) => t.status === OrderStatus.QA_SOAR);
        return needsApproving.length + needsQA.length;
    }, [tasks]);

    if (!user) return <React.Fragment />;

    const dashboard: NavigationPermissionItem = { name: 'Dashboard', route: '/', requiredRole: UserRole.REPORTS };
    const dataLake: NavigationPermissionItem = {
        name: 'Data Lake',
        route: '/analytics',
        requiredRole: UserRole.ANALYTICS,
    };

    const enquiries: NavigationPermissionItem = {
        name: 'Manage Enquiries',
        route: '/contact-us',
        requiredRole: UserRole.CONTACT_US,
        badgeCount: pendingContactUsInquires,
    };
    const uploadQueue: NavigationPermissionItem = {
        name: 'Upload Queue',
        route: '/reviews',
        requiredRole: UserRole.SUPER_MAP,
        badgeCount: pendingUploadReviews,
    };
    const satelliteOrders: NavigationPermissionItem = {
        name: 'Satellite Orders',
        route: '/orders',
        requiredRole: UserRole.ORDERS_ADMIN,
        badgeCount: pendingSatelliteOrders,
    };
    const newSatelliteOrder: NavigationPermissionItem = {
        name: 'Create New Custom Order',
        route: '/suppliers/new-order',
        requiredRole: UserRole.ORDERS_ADMIN,
    };
    const vouchers: NavigationPermissionItem = {
        name: 'Vouchers',
        route: '/vouchers',
        requiredRole: UserRole.VOUCHERS,
    };
    const soarPlus: NavigationPermissionItem = {
        name: 'Soar+ Management',
        route: '/subdomains',
        requiredRole: UserRole.REPORTS,
    };
    const campaignTracker = { name: 'Campaign Tracker', route: '/tracker', requiredRole: UserRole.REPORTS };

    const cloudFlareReport: NavigationPermissionItem = {
        name: 'Traffic Report',
        route: '/reports/traffic',
        requiredRole: UserRole.REPORTS,
    };
    const userReport: NavigationPermissionItem = {
        name: 'User Report',
        route: '/reports/users',
        requiredRole: UserRole.REPORTS,
    };
    const uploadReport: NavigationPermissionItem = {
        name: 'Upload Report',
        route: '/reports/uploads',
        requiredRole: UserRole.REPORTS,
    };
    const tileEngineReport: NavigationPermissionItem = {
        name: 'Tile Engine Report',
        route: '/reports/tiles',
        requiredRole: UserRole.REPORTS,
    };
    const commentReport: NavigationPermissionItem = {
        name: 'Comments Report',
        route: '/reports/comments',
        requiredRole: UserRole.REPORTS,
    };
    const hashtagReport: NavigationPermissionItem = {
        name: 'Hashtag Report',
        route: '/reports/hashtags',
        requiredRole: UserRole.REPORTS,
    };
    const drawProjectsReport: NavigationPermissionItem = {
        name: 'Draw Projects Report',
        route: '/reports/draw-projects',
        requiredRole: UserRole.REPORTS_DRAW_PROJECT,
    };

    const manageMaps: NavigationPermissionItem = {
        name: 'Manage Maps',
        route: '/search',
        requiredRole: UserRole.SUPER_MAP,
    };
    const manageWMS: NavigationPermissionItem = {
        name: 'Manage WMS/WMTS',
        route: '/wms',
        requiredRole: UserRole.SUPER_MAP,
    };
    const manageTMS: NavigationPermissionItem = { name: 'Manage TMS', route: '/tms', requiredRole: UserRole.TMS };
    const managedProfiles: NavigationPermissionItem = {
        name: 'Managed Profiles',
        route: '/profiles',
        requiredRole: UserRole.TMS,
    };
    const manageSTAC: NavigationPermissionItem = { name: 'Manage STAC', route: '/stac', requiredRole: UserRole.STAC };

    const manageUsers: NavigationPermissionItem = {
        name: 'Manage Users',
        route: '/users',
        requiredRole: UserRole.USERS_ADMIN,
    };

    const manageComments: NavigationPermissionItem = {
        name: 'Manage Comments',
        route: '/comments',
        requiredRole: UserRole.COMMENTS,
    };

    const manageFeatured: NavigationPermissionItem = {
        name: 'Manage Featured',
        route: '/featured',
        requiredRole: UserRole.SUPER_MAP,
    };

    const manageReported: NavigationPermissionItem = {
        name: 'Manage Reported Maps',
        route: '/reported-maps',
        requiredRole: UserRole.CONTACT_US,
        badgeCount: pendingReportedMaps,
    };

    const manageModerators: NavigationPermissionItem = {
        name: 'Manage Moderators',
        route: '/moderators',
        requiredRole: UserRole.USERS_ADMIN,
    };

    const manageReputableUsers: NavigationPermissionItem = {
        name: 'Manage Reputable Users',
        route: '/reputable-users',
        requiredRole: UserRole.USERS_ADMIN,
    };

    const homeItems = [dashboard, dataLake];
    const contentManagementItems = [
        uploadQueue,
        manageMaps,
        managedProfiles,
        manageWMS,
        manageTMS,
        manageSTAC,
        manageFeatured,
        manageReported,
    ];
    const satOrderItems = [satelliteOrders, newSatelliteOrder];
    const userItems = [
        manageUsers,
        manageModerators,
        manageReputableUsers,
        manageComments,
        enquiries,
        vouchers,
        soarPlus,
        campaignTracker,
    ];
    const reportItems = [
        cloudFlareReport,
        userReport,
        uploadReport,
        tileEngineReport,
        commentReport,
        hashtagReport,
        drawProjectsReport
    ];

    return (
        <NavList>
            <NavGroup name="Home" items={homeItems} />
            <NavGroup name="Content Management" items={contentManagementItems} />
            <NavGroup name="Satellite Orders Management" items={satOrderItems} />
            <NavGroup name="User Management" items={userItems} />
            <NavGroup name="Reports" items={reportItems} />
        </NavList>
    );
};

export default Navigation;

const NavList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid rgba(125, 125, 125, 0.3);
    height: 100%;
`;
