import React, { useEffect, useState } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import { CFMonthlyStatDTO } from '../../../api/model';
import { actionRefreshDrawProjectsReport } from '../../../store/ReportsStore/actions';
import { selectDrawProjectsReport, selectDrawProjectsReportError } from '../../../store/ReportsStore/selectors';
import SoarModal from '../../Shared/soar-modal';
import { Card, ErrorMessage, Spinner } from '../../style';
import ViewHelper from '../../view-helper';
import ReportTable from '../report-table';

const DrawProjectsTable = () => {
    const dispatch = useDispatch();
    const report = useSelector(selectDrawProjectsReport);
    const error = useSelector(selectDrawProjectsReportError)

    const [detail, setDetail] =
        useState<{ items: CFMonthlyStatDTO[]; columns: ColumnDescription[]; title: string }>(undefined);

    useEffect(() => {
        dispatch(actionRefreshDrawProjectsReport());
    }, []);

    const tableColumns: ColumnDescription[] = [
        {
            dataField: 'id',
            text: 'ID',
            csvText: "id",
            sort: true,
        },
        {
            dataField: 'title',
            text: 'Title',
            formatter: (value) => value,
            style: () => ({
                'max-width': '500px',
                'width': '400px',
                'overflow': 'wrap',
            }),
            csvText: "title",
            csvFormatter: (value) => value ?? "",
        },
        {
            dataField: 'public',
            text: 'Public',
            csvText: "public",
            csvFormatter: (value) => value.toLocaleString().toUpperCase(),
        },
        {
            dataField: 'createdAt',
            text: 'Created At',
            sort: true,
            formatter: (value) => ViewHelper.formatDateTimeFromISO(value),
            csvText: "created_at",
            csvFormatter: (value) => ViewHelper.formatDateTimeFromISO(value) ?? "",
        },
        {
            dataField: 'email',
            text: 'Owner',
            csvText: "email",
        },
        {
            dataField: 'deletedAt',
            text: 'Deleted At',
            formatter: (value) => ViewHelper.formatDateTimeFromISO(value),
            csvText: "deleted_at",
            csvFormatter: (value) => ViewHelper.formatDateTimeFromISO(value) ?? "",
        }
    ];

    if (error) {
        return (
            <Card height="552px">
                <ErrorMessage>{error}</ErrorMessage>
            </Card>
        );
    }

    if (!report) {
        return (
            <Card height="552px">
                <Spinner />
            </Card>
        );
    }

    return (
        <React.Fragment>
            <Card>
                <ReportTable items={report} columns={tableColumns} keyField={'dateLabel'} csvExport />
            </Card>
            {detail && (
                <SoarModal isOpen={true} title={detail.title} onClosed={() => setDetail(undefined)}>
                    <Card>
                        <ReportTable
                            items={detail.items}
                            columns={detail.columns}
                            keyField={'label'}
                            defaultSorted={[{ dataField: 'id', order: 'desc' }]}
                            csvExport
                        />
                    </Card>
                </SoarModal>
            )}
        </React.Fragment>
    );
};

export default DrawProjectsTable;
