import React from 'react';
import { Container, Title } from '../../style';
import DrawProjectsTable from './draw-projects-table';

const DrawProjects = () => {
    return (
        <Container>
            <Title>Draw Projects Report</Title>
            <DrawProjectsTable />
        </Container>
    );
};

export default DrawProjects;
